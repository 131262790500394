<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      stripePublicKey: this.stripePublicKey,
      reCaptchaSiteKey: this.reCaptchaSiteKey,
      paypalMerchantId: this.paypalMerchantId,
      i18n: this.i18n,
      campaigns: this.campaigns,
      newsletterFormUrl: this.newsletterFormUrl,
      paypalSuccessPageUrl: this.paypalSuccessPageUrl,
      paypalCancelPageUrl: this.paypalCancelPageUrl,
      ipnWebhook: this.ipnWebhook
    };
  },

  props: {
    stripePublicKey: { required: true, type: String },
    reCaptchaSiteKey: { required: true, type: String },
    paypalMerchantId: { required: true, type: String },
    newsletterFormUrl: { required: true, type: String },
    i18n: { required: true, type: Object },
    campaigns: { required: false, type: Array, default: () => [] },
    paypalSuccessPageUrl: { required: true, type: String },
    paypalCancelPageUrl: { required: true, type: String },
    ipnWebhook: { required: true, type: String }
  }
};
</script>
