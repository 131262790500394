<template>
  <div>
    <slot v-if="isOpened"></slot>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsletterPopup',

  props: {
    delay: { type: Number, default: 5000 }
  },

  data() {
    return { isOpened: false };
  },

  mounted() {
    this.timeout = setTimeout(this.open, this.delay);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    async open() {
      this.isOpened = true;

      let res = await axios({
        url: '/cookies/newsletter-popup',
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        data: { opened: true }
      });

      console.log(res);
    }
  }
};
</script>
