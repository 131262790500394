<script>
export default {
  props: {
    state: { required: true, type: Object }
  },

  render() {
    return this.$scopedSlots.default({
      state: this.stateData,
      setState: this.set
    });
  },

  data() {
    return {
      stateData: this.state
    };
  },

  methods: {
    set(property, value) {
      this.$set(this.stateData, property, value);
    }
  }
};
</script>
