export default function initImageCaption() {
  document.querySelectorAll('.content img').forEach((image) => {
    let alt = image.getAttribute('alt');

    if (alt && alt.trim().length < 1) return;

    let figure = document.createElement('figure');
    let caption = document.createElement('figcaption');
    caption.innerText = alt;
    caption.className = 'text-xs text-black text-opacity-70 mt-2';

    image.parentNode.insertBefore(figure, image);
    figure.appendChild(image);
    figure.appendChild(caption);
  });
}
