import axios from 'axios';

const csrfMeta = document.querySelector('meta[name="csrf-token"]');

let instance = axios.create({
  headers: {
    'X-CSRF-Token': csrfMeta ? csrfMeta.content : '',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export default instance;
