export default {
  state: {
    highlightStatics: false,
    highlightDeepLinks: false
  },

  toggleStaticsHighlight(value = !this.state.highlightStatics) {
    this.state.highlightStatics = value;

    if (value) {
      document.querySelector('body').classList.add('view-deep-links');
    }
    else {
      document.querySelector('body').classList.remove('view-deep-links');
    }
  },

  toggleDeepLinksHighlight(value = !this.state.highlightDeepLinks) {
    this.state.highlightDeepLinks = value;
  }
};
