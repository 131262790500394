<template>
    <div class="wrapper shadow-md">
    <button
      @click="toggleEditableContentHighlights"
      type="button"
      class="settings-panel-btn"
      title="Toggle editable content"
      aria-label="Toggle editable content"
    >
      <span
        v-if="settings.highlightDeepLinks || settings.highlightStaticLabels"
        v-html="icons.editableContent"
        class="icon"
      />
      <span v-else v-html="icons.editableContentActive" class="icon" />
    </button>

    <a
      v-if="metaDataId"
      :href="`/admin/meta_data/${metaDataId}/edit`"
      id="settings-meta-data"
      class="settings-panel-btn"
      title="Edit page meta data"
      aria-label="Edit page meta data"
    >
      <span v-html="icons.meta" class="icon" />
    </a>
  </div>
</template>

<script>
import settings from './settings';
import * as Icons from './icons';

export default {
  props: {
    canViewStatics: { required: false, type: Boolean, default: true },
    metaDataId: { required: false, type: String, default: null }
  },

  data() {
    return { settings: settings.state };
  },

  computed: {
    icons: () => Icons
  },

  methods: {
    toggleEditableContentHighlights() {
      settings.toggleDeepLinksHighlight();

      if (this.canViewStatics) {
        settings.toggleStaticsHighlight();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/hex/variables';

.wrapper {
  position: fixed;
  top: 96px;
  left: 0;
  padding: 8px;
  background: $white;
  border-radius: 0 $border-radius $border-radius 0;
  box-shadow: $shadow-lg;
  z-index: 9999999;
  line-height: 1;

  .settings-panel-btn {
    display: block;
    box-sizing: border-box;
    line-height: 1;
    height: 32px;
    width: 32px;
    padding: 7px;
    margin: 0;
    border-radius: 16px;
    text-align: center;
    background: none;
    border: none;

    .icon {
      width: 18px;
      height: 18px;
      display: inline-block;

      ::v-deep svg {
        width: 100%;
        height: auto;
      }
    }

    + .settings-panel-btn {
      margin-top: 4px;
    }
  }
}
</style>
