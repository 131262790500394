import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import throttle from 'lodash/throttle';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  data() {
    return {
      isOpened: false,
      currentPosition: 0,
      previousPosition: 0,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    isScrollingUp() {
      return this.previousPosition > this.currentPosition;
    },

    isDetached() {
      return this.currentPosition > 200;
    },

    isHiddenMain() {
      return this.isDetached && !this.isScrollingUp;
    },

    isHiddenSecondary() {
      return this.isDetached && this.isScrollingUp;
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    onResize: throttle(function() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth >= parseInt(fullConfig.theme.screens.lg, 10) && this.isOpened) {
        this.isOpened = false;
      }
    }, 300),

    onScroll: throttle(function() {
      this.previousPosition = this.currentPosition;
      this.currentPosition = window.scrollY;
    }, 300)
  }
};
