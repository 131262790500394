<template>
  <div>
    <slot name="trigger" :toggle="toggle"></slot>

    <div v-if="isVisible">
      <portal to="modal">
        <div @click.self="toggle" class="fixed inset-0 bg-black bg-opacity-80 z-1000">
          <div class="p-8 flex items-center justify-center w-full min-h-full">
            <slot name="content" :toggle="toggle"></slot>
          </div>

          <button
            v-if="showButton"
            type="button"
            @click="toggle"
            class="absolute top-0 right-0 mt-6 mr-8 text-3xl text-white leading-none p-4 rounded-full"
          >
            &times;
          </button>
        </div>
      </portal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shown: Boolean,
    showButton: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return { isVisible: this.shown };
  },

  watch: {
    isVisible: {
      handler() {
        if (this.isVisible) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      },
      immediate: true
    }
  },

  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },

    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    }
  }
};
</script>
