import Vue from 'vue';

Vue.component('donation-context', () => import('./DonationContext'));
Vue.component('donation-widget', () =>
  import(/* webpackChunkName: "donation-widget" */ './Widget/Widget.vue')
);
Vue.component('new-donate-widget', () =>
  import(/* webpackChunkName: "new-donate-widget" */ './NewDonate')
);
Vue.component('top-donation-page-ribbon', () =>
  import(/* webpackChunkName: "top-donation-page-ribbon" */ './TopDonationPageRibbon')
);

Vue.component('donate-sidebar', () =>
  import(/* webpackChunkName: "donate-sidebar" */ './DonateSidebar')
);
