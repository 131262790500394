import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import initSwiper from '../javascripts/swiper';
import initImageCaption from '../javascripts/image-caption';
import donationRibbon from '../javascripts/donation-ribbon';
import donationRibbonCheck from '../javascripts/donation-ribbon-check';
import formHandler from '../javascripts/form-handler';
import HasState from '../javascripts/HasState';
import Navigation from '../javascripts/navigation';
import Modal from '../javascripts/Modal';
import LoadScript from '../javascripts/LoadScript';
import SettingsPanel from '../javascripts/settings-panel/SettingsPanel';
import StaticScopeProvider from '../javascripts/statics/StaticScopeProvider';
import StaticString from '../javascripts/statics/StaticString';
import StaticStringInjector from '../javascripts/statics/StaticStringInjector';
import StaticStringRegistry from '../javascripts/statics/StaticStringRegistry';

import NewsletterPopup from '../javascripts/NewsletterPopup';
import '../javascripts/gdpr/index';
import '../javascripts/donate/index';
import '../javascripts/new-donate-widget/index';
import '../styles/main.scss';
import '../javascripts/statics/index';

// Import all images to be used with the `image_pack_tag`
require.context('../images', true);

Vue.use(PortalVue);
Vue.component('navigation', Navigation);
Vue.component('settings-panel', SettingsPanel);
Vue.component('static-scope-provider', StaticScopeProvider);
Vue.component('static-string', StaticString);
Vue.component('static-string-injector', StaticStringInjector);
Vue.component('static-string-registry', StaticStringRegistry);
Vue.component('has-state', HasState);
Vue.component('modal', Modal);
Vue.component('load-script', LoadScript);
Vue.component('newsletter-popup', NewsletterPopup);

Vue.component('tag-select', () =>
  import(/* webpackChunkName: "tag-select" */ '../javascripts/TagSelect')
);

Vue.component('mailbox', () => import(/* webpackChunkName: "mailbox" */ '../javascripts/Mailbox'));

Rails.start();
Turbolinks.start();
Turbolinks.setRootSelector('#turbo');

let app;


document.addEventListener('turbolinks:load', () => {
  app = new Vue({ el: '#app' });

  donationRibbon();
  donationRibbonCheck();
  formHandler();
  initSwiper();
  initImageCaption();

  document.querySelectorAll('[data-visit-select]').forEach((select) => {
    select.addEventListener('change', (e) => {
      Turbolinks.visit(e.target.value);
    });
  });

  document.querySelectorAll('.content a[href^="#"]').forEach((node) => {
    node.addEventListener('click', (e) => {
      e.preventDefault();
      let href = node.getAttribute('href');
      let target = document.querySelector(href);
      if (target) target.scrollIntoView({ behavior: 'smooth' });
    });
  });
});

document.addEventListener('turbolinks:visit', function teardown() {
  if (app) app.$destroy();
});
