<script>
  // import axios from 'axios'
  import axios from '../common/http/axios';
  import cloneDeep from 'lodash/cloneDeep';
  import eventDispatcher from 'gr-event-dispatcher';
  import get from 'lodash/get';
  import set from 'lodash/set';

  // Thin object that can dispatch event
  class Orchestator {}
  eventDispatcher.apply(Orchestator.prototype);

  export default {
    provide() {
      return {
        staticsRegistry: {
          watch: (key, scope, callback) => {
            this.watch(key, scope, callback);
          },

          stopWatching: (key, scope) => {
            this.stopWatching(key, scope);
          },

          updateValue: async (key, scope, newValue) => {
            await this.updateValue(key, scope, newValue);
          }
        }
      };
    },

    props: {
      preloadedStrings: { required: false, type: Object, default: () => ({}) }
    },

    data() {
      return {
        strings: cloneDeep(this.preloadedStrings)
      };
    },

    render() {
      return this.$slots.default;
    },

    created() {
      this._$orchestator = new Orchestator();
    },

    methods: {
      watch(key, scope, callback) {
        // Make sure the target string is presented
        if (typeof this.strings[scope] === 'undefined') {
          this.strings[scope] = {};
        }
        if (typeof this.strings[scope][key] === 'undefined') {
          this.strings[scope][key] = { key, value: key, scope };
        }

        this._$orchestator.addEventListener(this.eventName(key, scope), callback);

        // Trigger an immediate callback, so the child component can get the current value
        if (this.strings[scope][key].value === key) {
          return;
        }

        this._$orchestator.dispatchEvent({
          type: this.eventName(key, scope),
          value: this.strings[scope][key].value
        });
      },

      stopWatching(key, scope, callback) {
        this._$orchestator.removeEventListener(this.eventName(key, scope), callback);
      },

      async updateValue(key, scope, newValue) {
        this.strings[scope][key].value = newValue;

        await axios.post('/api/statics/sync', {
          static: this.strings[scope][key]
        });

        this._$orchestator.dispatchEvent({
          type: this.eventName(key, scope),
          value: newValue
        });
      },

      eventName(key, scope) {
        return `${scope}/${key}`;
      }
    }
  };
</script>
