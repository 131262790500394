import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Autoplay, Navigation, Pagination]);

export default function initSwiper() {
  document.querySelectorAll('[data-swiper]').forEach((node) => {
    let slidesPerView = node.dataset.swiperSlidesPerView || 1;
    let slidesPerViewMd = node.dataset.swiperSlidesPerViewMd || slidesPerView;
    let slidesPerViewLg = node.dataset.swiperSlidesPerViewLg || slidesPerViewMd;
    let slidesPerViewXl = node.dataset.swiperSlidesPerViewXl || slidesPerViewLg;

    new Swiper(node, {
      slidesPerView,
      spaceBetween: parseInt(node.dataset.swiperSpaceBetween || 0, 10),
      speed: 300,

      navigation: {
        nextEl: '[data-swiper-next]',
        prevEl: '[data-swiper-prev]'
      },

      ...getAutoplayOptions(node),
      ...getPaginationOptions(node),

      breakpoints: {
        576: {
          slidesPerView: slidesPerViewMd
        },
        992: {
          slidesPerView: slidesPerViewLg
        },
        1200: {
          slidesPerView: slidesPerViewXl
        }
      }
    });
  });
}

function getAutoplayOptions(node) {
  if (!node.dataset.swiperAutoplay) return {};

  if (node.dataset.swiperAutoplay.match(/\d+/)) {
    return { autoplay: { delay: parseInt(node.dataset.swiperAutoplay, 10) } };
  } else {
    return { autoplay: { delay: 5000 } };
  }
}

function getPaginationOptions(node) {
  if (!node.querySelector('[data-swiper-pagination]')) return {};

  return {
    pagination: {
      el: '[data-swiper-pagination]',
      clickable: true,
      bulletClass: node.dataset.swiperBulletClass,
      bulletActiveClass: node.dataset.swiperBulletActiveClass
    }
  };
}
