<template>
  <label class="donation-widget-btn-container" :class="{ '-invalid': isInvalid }">
    <input
      type="radio"
      name="amount"
      class="donation-widget-btns -amount"
      :class="{ active: isActive }"
      @click="$emit('select', price)"
      :checked="isActive"
    />
    <span> {{ price }} €</span>
  </label>
</template>

<script>
export default {
  props: {
    price: { required: true, type: Number },
    isActive: { required: false, type: Boolean, default: false },
    isInvalid: { required: false, type: Boolean, default: false }
  }
};
</script>
