<template>
  <div class="py-5 md:pt-24 px-4 lg:px-12 xl:px-24 donation-modal-bg-img">
    <div>
      <h1 class="text-white text-left text-3xl font-bold font-roboto">
        <static-string>Donation summary</static-string> 
      </h1>
    </div>

    <div class="border border-solid border-gray-100 bg-white text-black mt-8">
      <div class="border-b border-solid border-gray-100 sm:flex py-4 sm:py-6 items-center px-5 bg-gray-100">
        <div class="w-full sm:w-1/3 font-lato">
          <static-string> Amount:</static-string>
        </div>
        <div class="w-full sm:w-2/3 font-bold font-roboto text-2xl">{{ donation.amount }} EURO</div>
      </div>

      <div class="border-b border-solid border-gray-100 sm:flex py-4 sm:py-6 items-center px-5 bg-gray-100">
        <div class="w-full sm:w-1/3 font-lato">
          <static-string> Schedule: </static-string>
        </div>
        <div class="w-full sm:w-2/3 text-base font-bold uppercase font-roboto">
          {{ donation.interval }}
        </div>
      </div>

      <div class="border-b border-solid border-gray-100 sm:flex py-4 sm:py-6 items-center px-5 bg-gray-100">
        <div class="w-full sm:w-1/3 font-lato">
          <static-string> Campaign: </static-string>
        </div>
        <div class="w-full sm:w-2/3 text-base font-bold uppercase font-roboto">
          {{ donation.campaign.title }}
        </div>
      </div>
    </div>

    <div class="flex border-2 border-white items-center mt-16 mb-10 lg:mg-0 font-roboto bg-transparent">
      <div class="text-white text-4xl font-bold w-1/2 ml-3">
        <static-string> Total</static-string>
      </div>
      <div 
        v-if="donation.interval == 'once'"
        class="bg-gray-100 text-black font-bold w-1/2 py-5 px-3 text-center text-2xl"
      >
        {{ donation.amount }} EUR
      </div>

      <div 
        v-else
        class="text-black font-bold w-1/2 py-5 px-3 text-center text-2xl bg-gray-100"
      >
        {{ clalcMonthlyDonation }} EUR
      </div>
    </div>
  </div>
</template>

<script>
import HandleI18n from '../Utils/HandleI18n';

export default {
  mixins: [HandleI18n],

  props: {
    donation: { required: true, type: Object }
  },

  computed: {
    clalcMonthlyDonation() {
      return this.donation.amount * 12
    }
  }
};
</script>
