import donationRibbon from "./donation-ribbon.js";
import Vue from 'vue';
import DonationWidget from '../javascripts/new-donate-widget/Widget/Widget.vue';

export default function donationRibbonCheck() {
  const moreBtn = document.getElementById('more-button');

  if (moreBtn) {
    moreBtn.addEventListener('click', () => {
      setTimeout(() => {

        new Vue({
          el: '#more-stories',
          components: { DonationWidget } // Add your components here
        });
        donationRibbon();
      }, 500)
    })
  }
}