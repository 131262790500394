<template>
  <div class="social-media-icons flex flex-col items-center">
    <div class="icons flex mt-10">
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=link.liberties.eu/d5949d&quote='I have just donated to the Civil Liberties Union for Europe to support human rights defenders! Do you want to step up too? Find out more: link.liberties.eu/d5949d Thanks!'"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img :src="facebookIcon" alt="Facebook icon" />
      </a>

      <a
        href="http://www.twitter.com/share?url=link.liberties.eu/d3d980&text=I have just donated to the Civil Liberties Union for Europe to support human rights defenders! Do you want to step up too? Find out more:"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img :src="twitterIcon" alt="Twitter Icon icon" class="ml-8" />
      </a>
    </div>
    <span class="font-lato font-normal text-lg text-center w-52">
      <static-string>Share this good news with your friends!</static-string> 
    </span>
  </div>
</template>

<script>
import instagramIcon from '../../../images/icons/instagramIcon.svg';
import twitterIcon from '../../../images/icons/twitterIcon.svg';
import facebookIcon from '../../../images/icons/facebookIcon.svg';
import HandleI18n from '../Utils/HandleI18n';

export default {
  mixins: [HandleI18n],

  data() {
    return {
      instagramIcon: instagramIcon,
      twitterIcon: twitterIcon,
      facebookIcon: facebookIcon
    };
  }
};
</script>
