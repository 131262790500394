<template>
  <div></div>
</template>

<script>
export default {
  props: {
    id: String,
    src: String,
    title: String,
    eval: String
  },

  mounted() {
    if (this.eval) eval(this.eval);

    if (document.getElementById(this.id)) return;

    let script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = this.src;
    script.id = this.id;
    script.title = this.title;

    document.body.appendChild(script);
  }
};
</script>
