<template>
  <label class="donation-widget-btn-container justify-evenly" :class="{ '-invalid': isInvalid }">
    <input
      type="radio"
      name="amount"
      class="donation-widget-btns -amount shape-fix"
      :class="{ active: isActive }"
      @click="$emit('select', amount)"
      ref="radio"
    />

    <static-string ref='placeholderValue' class="hidden" >Custom Amount</static-string>
    <input
      ref="customInput"
      type="number"
      v-model.number="amount"
      min="1"
      placeholder="Custom amount"
      class="custom-amount-input"
      @focus="selectRadioOption"
    />
  </label>
</template>

<script>
export default {
  props: {
    isActive: { required: false, type: Boolean, default: false },
    isInvalid: { required: false, type: Boolean, default: false }
  },

  data() {
    return {
      amount: null
    };
  },

  watch: {
    amount(val) {
      // Emit the new value every time it's changed
      this.$emit('select', val);
    }
  },

  methods: {
    selectRadioOption() {
      this.$refs.radio.click();
    }
  },
  mounted() {
    this.$refs.customInput.placeholder = this.$refs.placeholderValue.$el.innerText
  }
};
</script>
